import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import { useHashState } from 'hooks/useHashState'
import { ComponentSubNavBar } from 'types/generated/contentful-types'
import { useSubNavBarContext } from 'context/SubNavBarContext'
import { useStickyHeaderContext } from 'context/StickyHeaderContext'

const SubNavBar = ({ navItemsCollection }: ComponentSubNavBar) => {
  const [activeHash, setActiveHash] = useState('')
  const navBarRef = useRef<HTMLDivElement | null>(null)
  const activeSection = useHashState({
    ref: navBarRef,
  })

  // eslint-disable-next-line
  const { stickyHeaderHeight, isPresent: isStickyPresent } =
    useStickyHeaderContext()

  const { setIsPresent, setSubNavHeight } = useSubNavBarContext()

  // register subnav height and presence in context when mounted
  useEffect(() => {
    setIsPresent(true)

    if (navBarRef.current) {
      setSubNavHeight(navBarRef.current.offsetHeight)
    }
    return () => {
      setIsPresent(false)
      setSubNavHeight(0)
    }
  }, [setIsPresent, setSubNavHeight])

  useEffect(() => {
    const activeHashItem = navItemsCollection?.items.find(
      (item) => item?.url === activeSection
    )
    if (activeHashItem) {
      setActiveHash(activeHashItem.url ?? '')
    }
  }, [activeSection, navItemsCollection])

  const setOffset = isStickyPresent ? `top-[48px]` : 'top-0'

  return (
    <div
      className={`sticky h-11 md:h-[58px] ${setOffset} z-30 justify-center lg:grid`}
      ref={navBarRef}
    >
      <div
        className={`flex flex-row-reverse items-center content-end justify-center h-full bg-sand before:content-[""] before:absolute before:h-[1px] before:w-screen before:-bottom-0 before:bg-darkShell w-screen px-3 -translate-x-4`}
      >
        <span id="subnav-header" className="sr-only">
          Page Section Navigation
        </span>
        <nav
          aria-labelledby="subnav-header"
          className="w-full overflow-x-scroll overflow-y-hidden md:flex md:justify-center lg:overflow-hidden"
        >
          <ul className="flex flex-row justify-between md:min-w-[600px]">
            {navItemsCollection &&
              navItemsCollection.items.map((item, i) => (
                <li
                  className={`sub-nav-item ${
                    activeHash === item?.url ? ' border-b border-blackSand' : ''
                  }`}
                  key={`SubNavItem_${i}`}
                >
                  <Link
                    href={`${item?.url}`}
                    scroll={false}
                    shallow
                    className="block h-full uppercase md:fora-text-navigation-1 fora-text-navigation-4"
                  >
                    {item?.label}
                  </Link>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default SubNavBar
